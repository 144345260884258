.contact {
    font-family: 'Source Code Pro';
    display: flex;
    padding: 12px 36px;
    gap: 32px;
    align-items: center;
}

.profile {
    align-content: center;
    width: 50%;
}

.message {
    align-content: center;
    padding: 12px 36px;
}

.ig-hyperlink {
    text-decoration: none;
    font-weight: 600;
    color: black;
}

@media (max-width: 1024px) {
    .contact {
        flex-direction: column;
        padding: 12px 0px;
    }
    .profile {
        align-content: center;
    }
}

