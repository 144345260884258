.nav {
    display: flex;
    justify-content: space-between;
    font-family: Abel;
    color: black;
    align-items: center;
    gap: 36px;
    padding: 12px 36px;
}

.site-title {
    font-size: 36px;
}

.nav a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 4px;
}

.nav ul {
    font-family: 'Source Code Pro';
    font-size: 16px;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 36px
}

.nav li.active {
    text-decoration: underline;
    text-underline-position: under;
}

.nav li:hover {
    text-decoration: underline;
    text-underline-position: under;
}

.mobile {
    display: none;
}

@media (max-width: 768px) {
    .site-title {
        z-index: 1;
    }

    .nav-opened {
        font-size: larger;
        background-color: white;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
    }

    .nav-closed {
        display: none !important;
    }

    .mobile {
        display:block;
        z-index: 1;
    }
}