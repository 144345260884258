.gallery {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    padding: 0px 12px;
}

.gallery .photos {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.gallery .photos:hover {
    filter: opacity(0.8);
}

@media (max-width: 1024px) {
    .gallery {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
}

@media (max-width: 768px) {
    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (max-width: 480px) {
    .gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
}

/* Pop-up Model*/
.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    transition: visibility 0.4s ease;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}